import React, { lazy, Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Loading from "./components/loading";
const Cooming = lazy(() => import("./pages/CoomingSoon/index"));
const Home = lazy(() => import("./pages/home/index"));
export default function Routers() {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Cooming />} />
          <Route path="/home" element={<Home />} />
          {/* paginas atuacao */}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}
