import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ProgressBar } from "react-loader-spinner";
const LOADING = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #393939;
  position: fixed;
  z-index: 9999999999999999999999999;
`;

export default function Loading() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {loading ? (
        <LOADING>
          <ProgressBar
            height="120"
            width="120"
            ariaLabel="progress-bar-loading"
            wrapperStyle={{}}
            wrapperClass="progress-bar-wrapper"
            borderColor="#312F2F"
            barColor="#BFA45F"
          />
        </LOADING>
      ) : (
        <></>
      )}
    </>
  );
}
