import React from "react";
import ReactDOM from "react-dom/client";
import "./reset.css";
import Routers from "./routes";

import ReactPixel from "react-facebook-pixel";
ReactPixel.init("2368334300012882", {});
function FacebookPixel() {
  React.useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("2368334300012882");
        ReactPixel.pageView();

        Routers.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  });
  return null;
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FacebookPixel />
    <Routers />
  </React.StrictMode>
);
